$(document).ready(function () {
    /**
     * 瀵艰埅鍔ㄦ晥
     */
    var animatedHeader = (function () {
        var docElem = document.documentElement,
            header = $('#header'),
            didScroll = false;

        scrollPage();
        window.addEventListener('scroll', function (event) {
            if (!didScroll) {
                didScroll = true;
                setTimeout(scrollPage, 250);
            }
        }, false);

        function scrollPage() {
            var scrollY = window.pageYOffset || docElem.scrollTop;
            if (scrollY >= 150) {
                header.removeClass('expand')
            } else {
                header.addClass('expand')
            }
            didScroll = false;
        }
    })();

    /**
     * 澶氱骇涓嬫媺鑿滃崟
     */
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }

        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');

        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-submenu .show').removeClass("show");
        });

        return false;
    });

    /**
     * 鐧惧害鍦板浘
     */
    var mapBox = $('#map-box');
    if (mapBox.length > 0) {
        var mapInfo = $.extend({
            lng: 87.586399, // 缁忓害
            lat: 43.875055, // 绾害
            title: '鏂扮枂鐧剧枂鍥剧綉缁滄湇鍔℃湁闄愬叕鍙?, // 鍏徃鍚嶇О
            message: '鍦板潃锛氫箤甯傛柊甯傚尯闀挎矙璺?58鍙风豢鑻戦泤绛?鍙锋ゼ4妤? // 鍦板潃
        }, window.MAP_INFO);

        // 鐧惧害鍦板浘API鍔熻兘
        var map = new BMap.Map("map-box");
        var point = new BMap.Point(mapInfo.lng, mapInfo.lat);
        var marker = new BMap.Marker(point); // 鍒涘缓鏍囨敞
        map.addOverlay(marker); // 灏嗘爣娉ㄦ坊鍔犲埌鍦板浘涓璡r
        map.centerAndZoom(point, 15);

        var opts = {
            width: 200, // 淇℃伅绐楀彛瀹藉害
            height: 100, // 淇℃伅绐楀彛楂樺害
            title: mapInfo.title, // 淇℃伅绐楀彛鏍囬
            enableMessage: true, //璁剧疆鍏佽淇℃伅绐楀彂閫佺煭鎭痋r
            message: mapInfo.message
        }
        var infoWindow = new BMap.InfoWindow(mapInfo.message, opts); // 鍒涘缓淇℃伅绐楀彛瀵硅薄 
        // map.openInfoWindow(infoWindow, point); //寮€鍚俊鎭獥鍙r
        marker.addEventListener("click", function () {
            map.openInfoWindow(infoWindow, point); //寮€鍚俊鎭獥鍙r
        });

        var top_left_navigation = new BMap.NavigationControl(); //宸︿笂瑙掞紝娣诲姞榛樿缂╂斁骞崇Щ鎺т欢

        map.addControl(top_left_navigation);
    }

    /**
     * 杩斿洖椤堕儴
     */
    var page = $(document);
    var scrollTotop = $('.scroll-to-top');
    if (scrollTotop.length > 0) {
        if (page.scrollTop() > 300) {
            scrollTotop.fadeIn();
        }
        page.on('scroll', function () {
            if (page.scrollTop() > 300) {
                scrollTotop.fadeIn();
            } else {
                scrollTotop.fadeOut();
            }
        });
        scrollTotop.click(function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    }


    // 棣栭〉澶у浘杞挱
    var indexBanner = new Swiper('.index-banner', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });


});